import { createRouter, createWebHistory } from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";
import {brandConfigMap} from "@/brand";

const routes = [
  {
    path: '/',
    redirect: getBaseRoute(),
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import("@/views/pages/WelcomePage"),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("@/views/pages/LoginPage"),
  },
  {
    path: '/create-username',
    name: 'create-username',
    component: () => import("@/views/pages/CreateUsernamePage"),
  },
  {
    path: '/landing',
    name: 'landing',
    component: () => import("@/views/pages/LandingPage"),
  },
  {
    path: '/doi-one',
    name: 'doi-one',
    component: () => import("@/views/pages/DoiOnePage"),
  },
  {
    path: '/doi-two',
    name: 'doi-two',
    component: () => import("@/views/pages/DoiTwoPage"),
  },
  {
    path: '/matchmaking',
    name: 'matchmaking',
    component: () => import("@/views/pages/MatchMakingPage"),
  },
  {
    path: '/in-game',
    name: 'in-game',
    component: () => import("@/views/pages/InGamePage"),
  },
  {
    path: '/result',
    name: 'result',
    component: () => import("@/views/pages/ResultPage"),
  },
  {
    path: '/awaiting-opponent',
    name: 'awaiting-opponent',
    component: () => import("@/views/pages/AwaitingOpponentPage"),
  },
  {
    path: '/my-friends',
    name: 'my-friends',
    component: () => import("@/views/pages/MyFriendsPage"),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import("@/views/pages/ProfilePage"),
  },
  {
    path: '/challenge-friend-waitingroom',
    name: 'challenge-friend-waitingroom',
    component: () => import("@/views/pages/ChallengeFriendWaitingRoomPage"),
  },
  {
    path: '/manage-subscription',
    name: 'manage-subscription',
    component: () => import("@/views/pages/ManageSubscriptionPage"),
  },
  {
    path: '/cancel-subscription',
    name: 'cancel-subscription',
    component: () => import("@/views/pages/CancelSubscriptionPage"),
  },
  {
    path: '/pending-game-start',
    name: 'pending-game-start',
    component: () => import("@/views/pages/PendingGameStartPage"),
  },
  {
    path: '/content-block',
    name: 'content-block',
    component: () => import("@/views/pages/ContentBlock"),
  },
  {
    path: '/insufficient-funds',
    name: 'insufficient-funds',
    component: () => import("@/views/pages/InsufficientFunds"),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import("@/views/pages/ErrorPage"),
  },
  {
    path: '/timeout-error',
    name: 'timeout-error',
    component: () => import("@/views/pages/TimeoutErrorPage"),
  },
  {
    path: '/wifi',
    name: 'wifi',
    component: () => import("@/views/pages/WifiPage"),
  },
  {
    path: '/rewards',
    name: 'RewardsPage',
    component: () => import("@/views/pages/RewardsPage"),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
})

function getBaseRoute(){
  const domain = window.origin;
  const brandConfig = brandConfigMap.get(domain) ? brandConfigMap.get(domain) : brandConfigMap.get('default');
  return brandConfig.baseRoute;
}

router.beforeEach(async (to, from, next) => {
  await userAuthenticate(to, from, next);
})

export default router
