import store from "@/store";
import DcbService from "@/services/dcbService";
import getUserSessionData from "@/functions/getUserSessionData";
import clearUserSessionData from "@/functions/clearUserSessionData";
import TriviaDataService from "@/services/triviaDataService";
import {handleActionClick} from "@/functions/handleActionClick";
import UtilsService from "@/services/utilsService";
import {Brands} from "@/brand/brandConfig";
import {ServiceIdConfig} from "@/config/serviceIdConfig";

export async function userAuthenticate(to, from, next) {
    const {brand} = store.state.brandConfig;
    if (brand === Brands.VodacomEvinaSouthAfricaEnglish) return await userAuthEncryptedMsisdn(to, from, next);
    else await userAuth(to, from, next);
}

async function userAuthEncryptedMsisdn(to, from, next) {
    if (store.state.user || to.name === 'wifi' || to.name === 'doi-two' || to.name === 'timeout-error') {
        store.commit('setIsLoading', false);
        return next();
    } else {
        store.commit('setIsLoading', true);
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token') ?? localStorage.getItem('token');
        const encryptedMsisdn = urlParams.get('encryptedMsisdn') ?? localStorage.getItem('encryptedMsisdn');
        const campaignId = urlParams.get('campaignId') || urlParams.get('cid');
        const googleClickId = urlParams.get('gclid');

        if (campaignId) setCampaignId(campaignId)
        if (googleClickId) setGoogleClickId(googleClickId)
        if (encryptedMsisdn && token) {
            return await storeUserDetails(encryptedMsisdn, token, next);
        } else {
            store.commit('setIsLoading', false);
            return next({name: 'wifi'});
        }
    }
}

function setGoogleClickId(googleClickId) {
    store.commit('setGoogleClickId', googleClickId);
}

async function setEncryptedUserData(userDataResponse, token = undefined, encryptedMsisdn = undefined) {
    await DcbService.trackLoginV2(token);
    store.commit('setUser', userDataResponse.data);
    store.commit('setToken', token);
    store.commit('setEncryptedMsisdn', encryptedMsisdn);
    localStorage.setItem('token', token);
    localStorage.setItem('encryptedMsisdn', encryptedMsisdn);
}

async function storeUserDetails(encryptedMsisdn, token, next) {
    const userDataResponse = await getUser(encryptedMsisdn, token);
    await setEncryptedUserData(userDataResponse, token, encryptedMsisdn);
    return await redirectBasedOnEncryptedUserStatus(userDataResponse.data, next);
}

function setServiceIdValues(serviceId) {
    localStorage.setItem('serviceId', serviceId);
    store.commit('setServiceId', serviceId);
}

async function getUser(encryptedMsisdn, token) {
    const userResponse = await DcbService.getUserViaEncryptedMsisdn(encryptedMsisdn, token, store.state.brandConfig.quizIncAllAccessServiceId);
    if (userResponse.data.status === 'Active') {
        setServiceIdValues(store.state.brandConfig.quizIncAllAccessServiceId);
        return userResponse;
    } else {
        const serviceId = await getMultiTierServiceV2(token);
        return await DcbService.getUserViaEncryptedMsisdn(encryptedMsisdn, token, serviceId);
    }
}

async function getMultiTierServiceV2(token) {
    const getMultiTierServiceResponse = await DcbService.getMultiTierServiceV2(token);
    const getServiceIdByDescriptionResponse = getServiceIdByDescription(getMultiTierServiceResponse.data);
    setServiceIdValues(getServiceIdByDescriptionResponse);
    return getServiceIdByDescriptionResponse
}

async function redirectBasedOnEncryptedUserStatus(user, next) {
    //TODO look at this logic !!
    store.commit('setIsLoading', false);
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');
    if (user.status === "Active") return next({name: 'landing'});
    else if (user.status === 'Content Blocked') return next({name: 'content-block'});
    else if (action) return await handleActionClick(user, action, next);
    else return next({name: 'doi-two'});
    // Need to implement this page else if(user?.contentBlockedEnabled) return next({name: 'ContentBlockOptIn'});
}

async function userAuth(to, from, next) {
    store.commit('setIsLoading', true);

    if (checkUserState(to)) {
        store.commit('setIsLoading', false);
        return next();
    } else {
        const userSessionData = getUserSessionData();
        if (userSessionData.msisdn && userSessionData.sessionExpiry && new Date(userSessionData.sessionExpiry) > new Date()) return await setUserData(userSessionData.msisdn, userSessionData.serviceId, next)
        else clearUserSessionData();
    }

    const urlParams = new URLSearchParams(window.location.search);
    const encryptedMsisdn = urlParams.get('e-msisdn');
    const campaignId = urlParams.get('campaignId');

    if (campaignId) setCampaignId(campaignId)
    if (encryptedMsisdn) return await encryptedMsisdnAuth(encryptedMsisdn, next);

    store.commit('setIsLoading', false);
    return next();
}

function setCampaignId(campaignId) {
    store.commit('setCampaignId', campaignId);
}

async function encryptedMsisdnAuth(encryptedMsisdn, next) {
    const decryptMsisdnResponse = await DcbService.decryptMsisdn(encryptedMsisdn);
    return await redirectBasedOnUserStatus(decryptMsisdnResponse.data.rawMsisdn, next);
}

async function getMultiTierService(msisdn) {
    const getMultiTierServiceResponse = await DcbService.getMultiTierService(msisdn);
    const getServiceIdByDescriptionResponse = getServiceIdByDescription(getMultiTierServiceResponse.data);
    store.commit('setServiceId', getServiceIdByDescriptionResponse);
    store.commit('setMsisdn', msisdn);
    return getServiceIdByDescriptionResponse;
}

async function redirectBasedOnUserStatus(decryptedMsisdn, next) {
    const userResponse = await getUserStatus(decryptedMsisdn, store.state.brandConfig.quizIncAllAccessServiceId);
    if (userResponse.status === "Active") {
        await trackLoginAndRedirect(decryptedMsisdn, store.state.brandConfig.quizIncAllAccessServiceId, userResponse, next);
    } else {
        const serviceId = await getMultiTierService(decryptedMsisdn);
        const userResponse = await getUserStatus(decryptedMsisdn, serviceId);
        await trackLoginAndRedirect(decryptedMsisdn, serviceId, userResponse, next);
    }
}

async function getUserStatus(decryptedMsisdn, serviceId) {
    const userResponse = await DcbService.getUserViaMsisdn(decryptedMsisdn, serviceId);
    return userResponse.data;
}

async function trackLoginAndRedirect(decryptedMsisdn, serviceId, userResponse, next) {
    await DcbService.trackLogin(decryptedMsisdn, serviceId);
    store.commit('setServiceId', userResponse.serviceId);
    await storeUserAndRedirect(userResponse, next);
}

async function storeUserAndRedirect(userResponse, next) {
    const getFundsResponse = await TriviaDataService.getUserGameFunds(userResponse.msisdn);
    userResponse.coinsConfig = getFundsResponse.data;
    store.commit('setUser', userResponse);
    store.commit('setIsLoading', false);
    if (userResponse.username === null) return next({name: 'create-username'});
    else return next({name: 'landing'});
}

function getServiceIdByDescription(array) {
    return array[0].Description;
}

function checkUserState(to) {
    return (store.state.user || to.name === 'TermsConditions' || to.name === 'login');
}

async function setUserData(msisdn, serviceId, next) {
    store.commit('setMsisdn', msisdn);
    store.commit('setServiceId', serviceId);
    const userResponse = await DcbService.getUserViaMsisdn(msisdn, serviceId);
    const getFundsResponse = await TriviaDataService.getUserGameFunds(store.state.msisdn);
    userResponse.data.coinsConfig = getFundsResponse.data;
    store.commit('setUser', userResponse.data);
    store.commit('setIsLoading', false);
    next();
}

