import {environmentConfig} from "@/config/enviromentConfig";
import axios from "axios";
import {ServiceIdConfig} from "@/config/serviceIdConfig";

const headers = {Authorization: `Basic ${environmentConfig.authHeader}`};

export default class DcbService {
    static async trackLogin(msisdn, serviceId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/track-login`, {
                    msisdn,
                    service: serviceId,
                    isWeb: 1,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async getUserViaToken(token) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/get-user-via-token`, {
                    token,
                    serviceId: ServiceIdConfig.vip,
                    externalRef: 1233564,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async requestOtp(msisdn, serviceId) {
        return await axios.post(`${environmentConfig.dcbService}/request-otp`, {
                msisdn: msisdn,
                serviceId,
            }, {headers}
        )
    }

    static async validateOtp(msisdn, serviceId, otp) {
        return await axios.post(`${environmentConfig.dcbService}/validate-otp`, {
                msisdn: msisdn,
                serviceId,
                otp: otp,
            }, {headers}
        )
    }

    static async getUserViaMsisdn(msisdn, serviceId, sessionId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/get-user-via-msisdn`, {
                    msisdn,
                    serviceId: serviceId,
                    externalRef: 12335642,
                    sessionId
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async trackUserUpgrade(msisdn, productId, oldService, newService) {
        return await axios.post(`${environmentConfig.dcbService}/track-multi-tier-upgrade`, {
                msisdn,
                productId,
                oldService,
                newService,
            }, {headers}
        )
    }

    static async trackUserUpgradeV2(encryptedMsisdn, productId, oldService, newService) {
        return await axios.post(`${environmentConfig.dcbService}/track-multi-tier-upgrade/v2`, {
                encryptedMsisdn: encryptedMsisdn,
                productId,
                oldService,
                newService,
            }, {headers}
        )
    }

    static async getMultiTierService(msisdn) {
        return await axios.post(`${environmentConfig.dcbService}/get-multi-tier-service`, {
                msisdn: msisdn,
                productId: ServiceIdConfig.productId,
                acquisitionChannel: `Organic-Web`,
                campaignId: null
            }, {headers}
        )
    }

    static async getMultiTierServiceV2(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.dcbService}/get-multi-tier-service/v2`, {
                encryptedMsisdn: encryptedMsisdn,
                productId: ServiceIdConfig.productId,
                acquisitionChannel: `Organic-Web`,
                campaignId: null
            }, {headers}
        )
    }

    static async doiOne(msisdn) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/doi-one`, {
                    msisdn,
                    serviceId: ServiceIdConfig.vip,
                    origination: "Fanclash - Web"
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async doiTwo(msisdn) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/doi-two`, {
                    msisdn,
                    serviceId: ServiceIdConfig.vip,
                    origination: "Fanclash - Web"
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async doiCancel(msisdn, serviceId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/doi-cancel`, {
                    msisdn,
                    serviceId,
                    origination: "Fanclash - Web"
                },
                {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async subscribeUser(msisdn, campaignId, serviceId, sessionId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/subscribe`, {
                    msisdn,
                    serviceId,
                    "externalRef": 12335642,
                    revenuePartnerId: 33,
                    campaignId: campaignId,
                    acquisitionChannel: 'Organic-Web',
                    sessionId: sessionId,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async unsubscribeUser(msisdn, serviceId, sessionId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/unsubscribe`, {
                    msisdn,
                    serviceId,
                    sessionId: sessionId,
                    "externalRef": 12335642,
                },
                {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async createUsername(msisdn, serviceId, username) {
        return await axios.post(`${environmentConfig.dcbService}/update-username-from-msisdn`, {
                msisdn,
                serviceId,
                username
            }, {headers}
        )
    }


    static async decryptMsisdn(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.dcbService}/decrypt-msisdn`, {
                encryptedMsisdn,
            }, {headers}
        )
    }

    static async encryptUserMsisdn(msisdn) {
        return await axios.post(`${environmentConfig.dcbService}/encrypt-msisdn`, {
                msisdn: msisdn,
            }, {headers}
        )
    }

    static async getEvinaScript(te, msisdn) {
        const response = await axios.post(`${environmentConfig.dcbService}/get-evina-script`, {
                targetElement: te,
                msisdn: msisdn,
                serviceId: ServiceIdConfig.vip
            }, {headers}
        )
        return {
            script: Buffer.from(response.data.script.toString(), "base64").toString(),
            evinaToken: response.data.token
        };
    }

    static async createSessionId(msisdn) {
        try {
            const response = await axios.post(`${environmentConfig.dcbService}/create-session-id`, {
                    msisdn: msisdn,
                    serviceId: ServiceIdConfig.global
                }, {headers}
            )
            return response?.data;
        } catch (e) {
            return {authError: true};
        }
    }

    /**
     * Encrypted Msisdn Routes
     **/
    static async getUserViaEncryptedMsisdn(encryptedMsisdn, token, serviceId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/get-user-via-encrypted-msisdn/v2`, {
                    encryptedMsisdn: encryptedMsisdn,
                    token: token,
                    serviceId: serviceId,
                    externalRef: 1233564,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async createUsernameV2(encryptedMsisdn, serviceId, username) {
        return await axios.post(`${environmentConfig.dcbService}/update-username-from-msisdn/v2`, {
                encryptedMsisdn,
                serviceId: serviceId,
                username
            }, {headers}
        )
    }

    static async unsubscribeEncryptedUser(encryptedMsisdn, serviceId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/unsubscribe/v2`, {
                    encryptedMsisdn,
                    serviceId: serviceId,
                    "externalRef": 12335642,
                },
                {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async subscribeEncryptedUser(encryptedMsisdn, token, campaignId, evinaToken, contentOverride = undefined, googleClickId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/subscribe/v2`, {
                    encryptedMsisdn: encryptedMsisdn,
                    token: token,
                    serviceId: ServiceIdConfig.vip,
                    "externalRef": 12335642,
                    revenuePartnerId: 33,
                    campaignId: campaignId,
                    acquisitionChannel: 'Organic-Web',
                    evinaToken: evinaToken,
                    contentOverride: contentOverride,
                    googleClickId
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async trackLoginV2(encryptedMsisdn, serviceId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/track-login/v2`, {
                    encryptedMsisdn,
                    service: serviceId,
                    isWeb: 1,
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }
}
